<template>
    <div>
        <header class="exchange-header py-1">
            <div class="container-fluid">
                <nav class="navbar navbar-expand-lg">
                    <!-- ____________EXCHANGE LOGO____________ -->
                    <!-- <div class="logo-box"> -->
                    <router-link class="navbar-brand exchange-logo" to="/"><img src="../assets/logo.png"
                            class="img-fluid me-5" style="width: 255px;"></router-link>
                    <button @click="hidden = !hidden" class="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false"
                        aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"><img :src="hidden ? hide : show"></span>
                    </button>

                    <!-- ____________ EXCHANGE NAV ITEMS ____________ -->
                    <div class="collapse navbar-collapse" id="navbarNav">
                        <ul class="navbar-nav align-items-xl-center me-auto mb-2 mb-lg-0 ms-0 ms-lg-5">
                            <!-- EXCHANGE -->
                            <li class="nav-item" @click="closeToggle">
                                <router-link class="nav-link" to="/marketview">Market</router-link>
                            </li>
                            <!--Trade Dropdown -->
                            <li class="nav-item trade_dropdown position-relative">
                                <button class="btn  link_btn dropdown-toggle" type="button" id="navbarDropdown"
                                    data-bs-toggle="dropdown" aria-expanded="false"  :style="metaR == 'trade' ? 'color: var(--px-pink)' : ''" 
                                   >
                                    {{trade}}
                                </button>
                                <ul class="trade_box dropdown-menu p-0" aria-labelledby="navbarDropdown">

                                    <li>
                                        <router-link class="dropdown-item" to="/exchange">Spot</router-link>
                                    </li>
                                    <li>
                                        <router-link class="dropdown-item" to="/p2p">P2P</router-link>
                                    </li>
                                    <!-- <li>
                                        <router-link class="dropdown-item" to="/marginexchange">Margin</router-link> 

                                    </li>
                                    <li>
                                        <router-link class="dropdown-item" to="/futureexchange">Future Trading
                                        </router-link>
                                    </li>
                                     -->
                                </ul>
                            </li>
                            <!--Buy and Sell -->
                            <!-- <li class="nav-item" @click="closeToggle">
                                <router-link class="nav-link" aria-current="page" to="/buysell">Buy&Sell</router-link>
                            </li> -->

                            <!--Earn -->
                            <li class="nav-item earn_dropdown  position-relative">
                                <button class="btn  link_btn dropdown-toggle" type="button" id="navbarDropdown"
                                    data-bs-toggle="dropdown" aria-expanded="false"
                                    :style="metaR == 'earn' ? 'color: var(--px-pink)' : ''">
                                    {{earn}}
                                </button>
                                <ul class="earn_box dropdown-menu p-0" aria-labelledby="navbarDropdown_earn">

                                    <li>
                                        <router-link class="dropdown-item" to="/staking-table">Staking</router-link>
                                    </li>
                                    <li>
                                        <router-link class="dropdown-item" to="/launchpad">Launchpad</router-link>
                                    </li>
                                </ul>

                            </li>
                        </ul>

                        <!-- ___________ RIGHT SIDE NAVBAR ________________ -->

                        <div class="right-side d-flex">
                            <ul class="navbar-nav align-items-xl-center mb-2 mb-lg-0 ">

                                <!-- Orders -->
                                <li class="nav-item" @click="closeToggle" v-if="isLogin">
                                    <router-link class="nav-link" aria-current="page" to="/spotorder">Orders
                                    </router-link>
                                </li>

                                <!--FUNDS-->
                                <li class="nav-item" @click="closeToggle" v-if="isLogin">
                                    <router-link class="nav-link" aria-current="page" to="/portfolio">Funds
                                    </router-link>
                                </li>

                                <!-- DAY AND NIGHT MODE BUTTON -->
                                <li class="nav-item">
                                    <div class="d-flex day_night py-2 nav-link">
                                        <button type="button" class="btn p-0 px-0 px-xl-2" v-if="!moon">

                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                style="fill: var(--px-white);transform: ;msFilter:;"
                                                @click="themeChange(0,1)">
                                                <path
                                                    d="M6.995 12c0 2.761 2.246 5.007 5.007 5.007s5.007-2.246 5.007-5.007-2.246-5.007-5.007-5.007S6.995 9.239 6.995 12zM11 19h2v3h-2zm0-17h2v3h-2zm-9 9h3v2H2zm17 0h3v2h-3zM5.637 19.778l-1.414-1.414 2.121-2.121 1.414 1.414zM16.242 6.344l2.122-2.122 1.414 1.414-2.122 2.122zM6.344 7.759 4.223 5.637l1.415-1.414 2.12 2.122zm13.434 10.605-1.414 1.414-2.122-2.122 1.414-1.414z">
                                                </path>
                                            </svg>
                                        </button>
                                        <!-- <span class="py-1"> <img src="../assets/images/s-line.png" alt="icon"> </span> -->
                                        <button type="button" class="btn p-0 px-0 px-xl-2" v-else>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                style="fill: var(--px-white);transform: ;msFilter:;"
                                                @click="themeChange(1,1)">
                                                <path
                                                    d="M12 11.807A9.002 9.002 0 0 1 10.049 2a9.942 9.942 0 0 0-5.12 2.735c-3.905 3.905-3.905 10.237 0 14.142 3.906 3.906 10.237 3.905 14.143 0a9.946 9.946 0 0 0 2.735-5.119A9.003 9.003 0 0 1 12 11.807z">
                                                </path>
                                            </svg>
                                        </button>
                                    </div>

                                </li>

                                <!-- CURRENCY -->
                                <li class="nav-item currency_dropdown  position-relative">
                                    <!-- <router-link class="nav-link  dropdown-toggle" id="navbarDropdown2"
                                        data-bs-toggle="dropdown" aria-expanded="false" to="">INR</router-link> -->
                                    <!-- <ul class="currency_box dropdown-menu p-0" aria-labelledby="navbarDropdown2">
                                        <li>
                                            <router-link class="dropdown-item" to="">INR </router-link>
                                            <router-link class="dropdown-item" to=""> USDT </router-link>
                                            <router-link class="dropdown-item" to=""> BTC </router-link>
                                        </li>
                                    </ul> -->
                                    <!-- <select class="form-select" aria-label="Default select example"
                                        @change="setPriceType" v-model="priceType">
                                        <option value="inr">INR</option>
                                        <option value="usdt">USDT</option>
                                        <option value="btc">BTC</option>
                                    </select> -->

                                    <button class="drop_currency nav-link text-uppercase bg-transparent btn-drop
                                        dropdown-toggle border-0 d-flex align-items-center w-100" type="button"
                                        id="dropdownprice" data-bs-toggle="dropdown" aria-expanded="false"
                                        @click="toggle1 = false, toggle2 = false">
                                        <p class="mb-0 pe-1" style="color:var(--px-pink)">{{ priceType }} </p>
                                    </button>
                                    <ul class="currency_box dropdown-menu " aria-labelledby="dropdownprice">
                                        <li class="list_items  d-flex align-items-center ps-2 p-1"
                                            v-for="(data, index) in priceTypearr" :key="index"
                                            @click="setPriceType(data.type), priceType = data.type">
                                            <p class="mb-0 pe-1">{{ data.name}}</p>
                                        </li>
                                    </ul>

                                </li>

                                <!-- Download -->
                                <li class="nav-item">
                                    <button type="button" class="download_here download_btn nav-link"
                                        @click="toggle1 = !toggle1,toggle=false,toggle2= false"> Download
                                        <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                                                style="fill: var(--px-white)" viewBox="0 0 384 512">
                                                <path
                                                    d="M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z" />
                                            </svg>
                                        </span>
                                    </button>
                                    <div class="download_box px-3 py-2" v-if="toggle1">
                                        <img src="../assets/images/code-qr.png" alt="icon" class="img-fluid">
                                        <div class="d-flex justify-content-around">
                                            <img src="../assets/images/for-i.png" alt="icon" class="img-fluid">
                                            <span class="px-2"> | </span>
                                            <img src="../assets/images/for-android.png" alt="icon" class="img-fluid">
                                        </div>
                                    </div>
                                </li>
                                <!-- GET STARTED -->
                                <li class="nav-item" @click="closeToggle" v-if="!isLogin">
                                    <div class="get_started button" style="cursor: pointer;">
                                        <router-link to="/login" type="button" class="cus_btn shadow-none">GET STARTED
                                        </router-link>
                                    </div>
                                </li>
                                <!-- USER PROFILE-->
                                <li class="nav-item me-0" v-else>
                                    <div class="user_profile d-flex align-items-center nav-link "
                                        @click="toggle2 = !toggle2,toggle=false,toggle1 = false">
                                        <div class="user_name me-2">
                                            <h6 class="mb-0">Hi {{ user.fname }} </h6>
                                        </div>
                                        <div class="user_img">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25"
                                                style="fill: var(--px-white)" viewBox="0 0 512 512">
                                                <path
                                                    d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 128c39.77 0 72 32.24 72 72S295.8 272 256 272c-39.76 0-72-32.24-72-72S216.2 128 256 128zM256 448c-52.93 0-100.9-21.53-135.7-56.29C136.5 349.9 176.5 320 224 320h64c47.54 0 87.54 29.88 103.7 71.71C356.9 426.5 308.9 448 256 448z" />
                                            </svg>
                                        </div>
                                        <div class="profile_box" v-if="toggle2">
                                            <ul class="list-unstyled">

                                                <router-link to="/profile">
                                                    <li> Profile </li>
                                                </router-link>

                                                <router-link to="/2f-authentication">
                                                    <li> Security </li>
                                                </router-link>
                                                <router-link to="/referrallink">
                                                    <li> Referral </li>
                                                </router-link>

                                                <router-link to="/trading-report">
                                                    <li> Reports </li>
                                                </router-link>

                                                <router-link to="/support">
                                                    <li> Help </li>
                                                </router-link>

                                                <li> <button type="button" class="btn p-0 logout_btn"
                                                        v-on:click="logoutAlert()"> Logout </button>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </li>

                            </ul>
                        </div>

                    </div>

                </nav>

            </div>
        </header>
    </div>
</template>

<script>
import ApiClass from '@/api/api'
export default {
    name: 'MainHeader',
    props:
    {
        metaR: String,
        rname: String
    },

    data() {
        return {
            hidden: true,
            scrollPosition: null,
            hide: require('@/assets/images/icons/toggle.svg'),
            show: require('@/assets/images/icons/cancel.svg'),
            toggle: false,
            toggle1: false,
            toggle2: false,
            toggleVal: true,
            isLogin: false,
            user: '',
            moon: false,
            c_theme:1,
            priceTypearr: [{ name: 'INR', type: 'inr' },
            {
                name: 'USDT', type: 'usdt'
            },
            {
                name: 'BTC', type: 'btc'
            }],
            priceType: 'inr',
            trade: 'Trade',
            earn: 'Earn',
            route_name:''

        }
    },
    mounted() {
        
        this.c_theme = this.$store.getters.getTheme
        this.c_theme == 0 ? this.moon = true :false

        this.themeChange(this.$store.getters.getTheme, 0);
        this.current_route(this.rname);
       
        this.isLogin = (localStorage.getItem('token')) ? true : false;
        // alert(this.isLogin)
        this.priceType = this.$store.getters.getPriceType;
        this.user = (JSON.parse(localStorage.getItem('user')) || (this.$store.getters.getUser)) ?? '';
        // console.log(this.user);
    },

    watch:
    {
        "$store.getters.getUser": {
            handler: function () {
                this.user = JSON.parse(localStorage.getItem('user')) ?? (this.$store.getters.getUser);
            },
            deep: true
        },
    },
    methods: {
        current_route(cr){
            // console.log('crrr===',cr)
            if(cr == 'ExchangeView'){
                return this.trade = 'Spot'
            }

            if(cr == 'P2PView'){
                return this.trade = 'P2P'
            }

            if(cr == 'StakingTableView'){
                return this.earn = 'Staking'
            }

            if(cr == 'launchpad'){
                return this.earn = 'Launchpad'
            }

            // console.log('trade===',this.trade)
        },
        closeToggle() {
            this.hidden = true
            const menuToggle = document.getElementById("navbarNav");
            menuToggle.classList.remove("show");
        },
        themeChange(val = null, isMount) {
            // console.log({val:val,mount:isMount})
            this.$store.commit('setTheme', val);
            (isMount == 1) ? this.moon = !this.moon : '';
            var light = document.getElementById("app").classList;
            (val == 0) ? light.add("light") : light.remove("light");

        },
        // updateScroll() {
        //     this.scrollPosition = window.scrollY
        // },
        logoutAlert() {
            this.$swal.fire({
                title: "Please Confirm!",
                text: "Are you sure you want to Logout!",
                icon: "warning",
                iconColor: "#d23a82",
                showCloseButton: true,
                showCancelButton: true,
                closeOnConfirm: false,
                confirmButtonColor: "#d23a82",
                cancelButtonColor: "#d23a82",
                confirmButtonText: "logout from all devices!",
                showLoaderOnConfirm: true,
                cancelButtonText: "logout from this device only!",
                showLoaderOnCancel: true,
            })
                .then((result) => {
                    if (result.isConfirmed) {
                        // new Promise((resolve) => setTimeout(resolve, 5000));
                        this.inLogout("hardlogout");

                    } else if (result.dismiss == this.$swal.DismissReason.cancel) {
                        this.inLogout("logout");
                    }
                });
        },

        async inLogout(type) {
            let res = await ApiClass.deleteRequest(type, true);
            if (res?.data) {
                if (res.data.status_code == 1) {
                    localStorage.clear();
                    this.$store.commit("setLogin", false);
                    location.replace("/exchange");
                    this.success(res.data.message);
                } else {
                    this.failed(res.data.message);
                }
            }
        },
        setPriceType(v) {
            this.$store.commit('setPriceType', v);
        },
    }
}
</script>

<style scoped>
.exchange-header {
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 99;
    /* background-color: var(--light-grey); */
    background-color: var(--head-foot-bg);
}

a.nav-link,
button,
.nav-item {
    font-size: 15px;
    color: var(--px-white);
    font-weight: 500;
    margin-right: 6px;
}

.exchange-header .router-link-active.nav-link,
.lbm-header .nav-link:hover {
    font-weight: 600;
    color: var(--px-pink);
}

nav ul li a:hover,
.router-link .nav-link.active {
    color: var(--px-pink);
}


.activeIt {
    color: var(--px-pink);
}

.navbar-toggler:focus {
    box-shadow: none;
    outline: none;
}

.nav-item .btn-info:hover {
    background-color: var(--just-bit-transparent);
    border: 1px solid var(--px-pink);
}

.btn-check:focus+.btn,
.btn:focus {
    box-shadow: none;
}

.cus_btn {
    display: inline-block;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    padding: 6px 18px;
    font-size: 14px;
    border-radius: 5px;
    background: var(--next-btn);
    border: 1px solid var(--px-pink);
    transition: all 0.2s ease-in-out;
    color: var(--white) !important;
}

.cus_btn:hover {
    background-color: var(--px-pink);
    transition: all 0.2s ease-in-out;
}

.download_btn {
    cursor: pointer;
    padding: 6px 6px;
    font-size: 14px;
    border-radius: 5px;
    background-color: transparent;
    border: 1px solid transparent;
    transition: all 0.2s ease-in-out;
    color: var(--px-white) !important;
}

.get_started {
    position: relative;
}

.log_box {
    position: absolute;
    top: 63px;
    right: 9px;
    /* height: 80px;
    width: 180px; */
    z-index: 9;
    background: var(--px-white);
    box-shadow: 0px 0px 5px #605c5c;
}

.log_box a {
    color: var(--log-grey);
    text-decoration: none;
    font-size: 15px;
    font-weight: 500;

}

.log_box a:hover {
    text-decoration: underline;
}

.log_box span {
    font-size: 20px;
}

.download_here {
    position: relative;
}

.download_box,
.profile_box {
    position: absolute;
    top: 63px;
    z-index: 9;
    background: var(--white);
    color: var(--black);
    box-shadow: 0px 0px 5px #605c5c;
}

.profile_box {
    right: 15px !important;
    background: var(--drop-bg);
    color: var(--white);
    box-shadow: 0px 0px 5px #605c5c;
    min-width: 10rem;
}

.logout_btn:hover {
    color: var(--white);
}

.profile_box ul li {
    padding: 0.25rem 1rem;
}

.profile_box ul a li {
    text-decoration: none;
    color: var(--px-white);
}

.profile_box a {
    text-decoration: none;
}

.profile_box ul li:hover {
    color: var(--white);
    background-color: var(--drop-hover);
    cursor: pointer;
}

/*______DROPDOWN CSS______*/

.dropdown-menu {
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: 0.25rem;
    background: var(--drop-bg);
    color: var(--white);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

/* .trade_dropdown .dropdown-menu {
    left: 360px ;
}

.earn_dropdown .dropdown-menu {
    left: 585px ;
}

.currency_dropdown .dropdown-menu {
    left: 1400px ;
}  */

.dropdown-item:focus,
.dropdown-item:hover {
    color: #1e2125;
    background-color: var(--drop-hover);
}

.trade_box .dropdown-item,
.currency_box .dropdown-item,
.earn_box .dropdown-item {
    color: var(--px-white);
    font-size: 14px;
}

.trade_box P {
    font-size: 14px;
}

.logo-box {
    display: flex;
    align-items: center;
    gap: 160px;
}

/* user profile */
.user_name h6 {
    color: var(--px-white);
    font-size: 14px;
}

.light_image {
    filter: invert(1);
}

.user_profile {
    cursor: pointer;
}

.currency_dropdown .nav-link:focus,
.nav-link:hover {
    color: var(--px-pink);
}

.trade_box .dropdown-item,
.currency_box .dropdown-item,
.earn_box .dropdown-item {
    color: var(--px-white);
    font-size: 14px;
}

.currency_box li {
    font-size: 14px;
    color: var(--px-white);
}

.currency_box li:hover {
    background-color: var(--drop-hover);
    cursor: pointer;

}

.link_btn {
    background-color: transparent;
    border: 1px solid transparent;
}

.link_btn:hover {
    color: var(--px-pink);
}

.currency_dropdown .dropdown-toggle::after {
    border-top: .3em solid var(--px-pink) !important;
}

/* ******************************* RESPONSIVE CSS ******************************* */
@media (min-width:320px) and (max-width:991px) {
    .navbar-expand-lg .navbar-nav .nav-link {
        padding-left: 15px;
    }

    .trade_dropdown .dropdown-menu {
        left: 260px;
    }

    .earn_dropdown .dropdown-menu {
        left: 500px;
    }

    .currency_dropdown .dropdown-menu {
        left: 1400px;
    }
}
</style>

