import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate';

export default createStore({
  plugins: [createPersistedState()],
  state: {
   
    payment_name: null,
    show_upi: null,
    
  },
  getters: {
   
    getpayment_name(state) {
      return state.payment_name;
    },
    getshow_upi(state) {
      return state.show_upi;
    },

  },
  mutations: {
   
    setpayment_name(state, payload) {
      state.payment_name = payload
    },
    setshow_upi(state, payload) {
      state.show_upi = payload
    },
   

  },

})
