import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import store from "../store/index.js";

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { routen: 'market' }
  },
  {
    path: '/portfolio',
    name: 'PortfolioView',
    component: () => import(/* webpackChunkName: "portfolio" */'../views/PortfolioView.vue'),
    meta: { routen: 'portfolio', authOnly: true }
  },
  {
    path: '/buysell',
    name: 'BuySellView',
    component: () => import(/* webpackChunkName: "buysell" */'../views/BuySellView.vue'),

  },
  //************PORTFOLIO SETTING**************//
  {
    path: '/portfolio/mobilesetting',
    name: 'MobilePortfolioView',
    meta: { authOnly: true },
    component: () => import(/* webpackChunkName: "MobilePortfolioView" */'../views/Portfolio-setting/MobilePortfolioView.vue')
  },
  {
    path: '/profile',
    name: 'ProfileView',
    meta: { authOnly: true },
    component: () =>
      import(/* webpackChunkName: "profile" */'../views/ProfileView.vue'),
   
  },
  {
    path: '/kyc-setting',
    name: 'KycVerification',
    meta: { authOnly: true },
    component: () =>
      import(/* webpackChunkName: "KycVerification" */'../views/Portfolio-setting/KycVerification.vue'),

  },
  {
    path: '/bank-detail',
    name: 'BankDetails',
    meta: { authOnly: true },
    component: () =>
      import(/* webpackChunkName: "bank-detail" */'../views/Portfolio-setting/BankDetail.vue'),

  },
  {
    path: '/add-upi',
    name: 'UpiDetails',
    meta: { authOnly: true },
    component: () =>
      import(/* webpackChunkName: "UpiDetails" */'../views/Portfolio-setting/AddUpi.vue'),

  },
  {
    path: '/add-bank',
    name: 'AddBank',
    meta: { authOnly: true },
    component: () =>
      import(/* webpackChunkName: "AddBank" */'../views/Portfolio-setting/AddBank.vue'),

  },
  {
    path: '/2f-authentication',
    name: 'TwoFactorAuthentication',
    meta: { authOnly: true },
    component: () =>
      import(/* webpackChunkName: "TwoFactorAuthentication" */'../views/Portfolio-setting/TwoFactorAuthentication.vue'),

  },
  {
    path: '/activity-log',
    name: 'ActivityView',
    meta: { authOnly: true },
    component: () =>
      import(/* webpackChunkName: "ActivityView" */'../views/Portfolio-setting/ActivityView.vue'),

  },

  // FUNDS
  {
    path: '/withdrawhistory',
    name: 'WithdrawHistoryView',
    meta: { authOnly: true },
    component: () => import(/* webpackChunkName: "WithdrawHistoryView" */'../views/Portfolio-setting/Funds/WithdrawHistoryView.vue')
  },
  {
    path: '/deposithistory',
    name: 'DepositHistoryView',
    meta: { authOnly: true },
    component: () => import(/* webpackChunkName: "DepositHistoryView" */'../views/Portfolio-setting/Funds/DepositHistoryView.vue')
  },
  {
    path: '/withdraw-model',
    name: 'WithdrawModel',
    meta: { authOnly: true },
    component: () => import(/* webpackChunkName: "WithdrawModel" */'../views/Portfolio-setting/Funds/WithdrawModel.vue')
  },
  {
    path: '/deposit-model',
    name: 'DepositModel',
    meta: { authOnly: true },
    component: () => import(/* webpackChunkName: "DepositModel" */'../views/Portfolio-setting/Funds/DepositModel.vue')
  },
  {
    path: '/withdraw-pwset',
    name: 'WithdrawPasswordset',
    meta: { authOnly: true },
    component: () => import(/* webpackChunkName: "WithdrawPasswordset" */'../views/Portfolio-setting/Funds/WithdrawPasswordset.vue')
  },
  {
    path: '/wallet',
    name: 'WalletView',
    meta: { authOnly: true },
    component: () => import(/* webpackChunkName: "WalletView" */'../views/Portfolio-setting/Wallet/WalletView.vue')
  },
  {
    path: '/wallet-history',
    name: 'WallethistoryView',
    meta: { authOnly: true },
    component: () => import(/* webpackChunkName: "WallethistoryView" */'../views/Portfolio-setting/Wallet/WallethistoryView.vue')
  },
  // ___________TRADING REPORT
  {
    path: '/trading-report',
    name: 'TradingReportView',
    meta: { authOnly: true },
    component: () => import(/* webpackChunkName: "TradingReportView" */'../views/Portfolio-setting/TradingReportView.vue')
  },
  // ___________AUTH PAGES
  {
    path: '/login',
    name: 'LoginView',
    meta: { guestOnly: true },
    component: () =>
      import(/* webpackChunkName: "LoginView" */'../views/Auth/LoginView.vue')
  },
  {
    path: '/register',
    name: 'RegisterView',
    meta: { guestOnly: true },
    component: () =>
      import(/* webpackChunkName: "RegisterView" */'../views/Auth/RegisterView.vue')
  },
  {
    path: '/forgotpassword',
    name: 'ForgotpasswordView',
    meta: { guestOnly: true },
    component: () =>
      import(/* webpackChunkName: "ForgotpasswordView" */'../views/Auth/ForgotpasswordView.vue')
  },
  {
    path: '/emailverification',
    name: 'VerificationView',
    meta: { guestOnly: true },
    component: () =>
      import(/* webpackChunkName: "VerificationView" */'../views/Auth/VerificationView.vue')
  },
  {
    path: '/reset-password',
    name: 'ResetpasswordView',
    meta: { guestOnly: true },
    component: () =>
      import(/* webpackChunkName: "ResetpasswordView" */'../views/Auth/ResetpasswordView.vue')
  },
  {
    path: '/verifymail',
    name: 'EmailverifyView',
    meta: { guestOnly: true },
    component: () =>
      import(/* webpackChunkName: "EmailverifyView" */'../views/Auth/EmailverifyView.vue')
  },

  //____________REFERRAL LINK
  {
    path: '/referrallink',
    name: 'ReferralView',
    meta: { authOnly: true },
    component: () =>
      import( /* webpackChunkName: "ReferralView" */ '../views/ReferralView.vue')
  },

  //__________________P2P
  {
    path: '/p2p',
    name: 'P2PView',
    component: () =>
      import( /* webpackChunkName: "ReferralView" */ '../views/P2PView.vue'),
    meta: { routen: 'trade',authOnly: true }
  },
  {
    path: '/p2pbuymodal',
    name: 'P2PBuyModal',
    meta: { authOnly: true },
    component: () =>
      import( /* webpackChunkName: "P2PBuyModal" */ '../views/P2PBuyModal.vue')
  },
  {
    path: '/p2psellmodal',
    name: 'P2PSellModal',
    meta: { authOnly: true },
    component: () =>
      import( /* webpackChunkName: "P2PSellModal" */ '../views/P2PSellModal.vue')
  },
  //______________________EXCHANGE
  {
    path: '/exchange',
    name: 'ExchangeView',
    component: () =>
      import(/* webpackChunkName: "ExchangeView" */'../views/ExchangeView.vue'),
    meta: { routen: 'trade' }
  },
  {
    path: '/marginexchange',
    name: 'MergeView',
    component: () =>
      import(/* webpackChunkName: "MergeView" */'../views/MarginView.vue'),
    meta: { routen: 'trade' }

  },
  // setting page
  {
    path: '/spotorder',
    name: 'SpotHistoryView',
    meta: { authOnly: true },
    component: () =>
      import(/* webpackChunkName: "SpotHistoryView" */'../views/Orderhistory/SpotHistoryView.vue')
  },
  {
    path: '/marginorder',
    name: 'MarginHistoryView',
    meta: { authOnly: true },
    component: () =>
      import(/* webpackChunkName: "MarginHistoryView" */'../views/Orderhistory/MarginHistoryView.vue')
  },
  {
    path: '/futureorder',
    name: 'FutureOrderView',
    meta: { authOnly: true },
    component: () =>
      import(/* webpackChunkName: "FutureOrderView" */'../views/Orderhistory/FutureOrderView.vue')
  },
  {
    path: '/buysell-order',
    name: 'BuysellOrderView',
    meta: { authOnly: true },
    component: () =>
      import(/* webpackChunkName: "BuysellOrderView" */'../views/Orderhistory/BuysellOrderView.vue')
  },
  {
    path: '/p2p-order',
    name: 'P2pOrderView',
    meta: { authOnly: true },
    component: () =>
      import(/* webpackChunkName: "P2pOrderView" */'../views/Orderhistory/P2pOrderView.vue')
  },
  {
    path: '/staking-order',
    name: 'StakingOrderView',
    meta: { authOnly: true },
    component: () =>
      import(/* webpackChunkName: "StakingOrderView" */'../views/Orderhistory/StakingOrderView.vue'),
      // meta: { routen: 'earn' }
  },
  {
    path: '/launchpad-order',
    name: 'LaunchpadOrderView',
    meta: { authOnly: true },
    component: () =>
      import(/* webpackChunkName: "LaunchpadOrderView" */'../views/Orderhistory/LaunchpadOrderView.vue'),
      // meta: { routen: 'earn' }

  },
  {
    path: '/support',
    name: 'SupportView',
    meta: { authOnly: true },
    component: () =>
      import(/* webpackChunkName: "SupportView" */'../views/SupportView.vue')
  },
  {
    path: '/contactus',
    name: 'ContactusView',
    meta: { authOnly: true },
    component: () =>
      import(/* webpackChunkName: "ContactusView" */'../views/ContactusView.vue')
  },
  {
    path: '/ticketlist',
    name: 'TicketlistView',
    meta: { authOnly: true },
    component: () =>
      import(/* webpackChunkName: "TicketlistView" */'../views/TicketlistView.vue')
  },
  {
    path: '/ticketlistmodal',
    name: 'TicketlistModal',
    meta: { authOnly: true },
    component: () =>
      import(/* webpackChunkName: "TicketlistModal" */'../views/TicketlistModal.vue')
  },
  {
    path: '/futureexchange',
    name: 'FutureExchange',
    component: () =>
      import(/* webpackChunkName: "FutureExchange" */'../views/FutureExchange.vue'),
    meta: { routen: 'trade' }
  },
  {
    path: '/content/:slug',
    name: 'ContentViewer',
    component: () =>
      import(/* webpackChunkName: "ContentViewer" */'../views/ContentViewer.vue'),
    meta: { routen: 'content' }
  },
  
  {
    path: '/staking-table',
    name: 'StakingTableView',
    component: () => import( /* webpackChunkName: "StakingTableView"  */ '../views/staking/StakingTableView.vue'),
    meta: { routen: 'earn' }
  },
  {
    path: '/stake',
    name: 'StakeView',
    component: () => import(/* webpackChunkName: "StakeView" */ '../views/staking/StakeView.vue')
  }, 
  {
    path: '/staking-history',
    name: 'StakingHistory',
    component: () => import(/* webpackChunkName: "StakingHistory" */ '../views/staking/StakingHistory.vue')
  },


  {
    path: '/launchpad',
    name: 'launchpad',
    component: () => import(/* webpackChunkName: "launchpad" */ '../views/Launchpad/LaunchpadView.vue'),
    meta: { routen: 'earn' }
  },

  {
    path: '/launchpadblog',
    name: 'launchpadblog',
    component: () => import(/* webpackChunkName: "launchpadblog" */ '../views/Launchpad/LaunchpadBlogView.vue')
  },

  {
    path: '/refferalmain',
    name: 'RefferalMain',
    component: () => import(/* webpackChunkName: "refferalmain" */ '../views/RefferalMainView.vue')
  },
  {
    path: '/marketview',
    name: 'MarketView',
    component: () => import(/* webpackChunkName: "MarketView" */ '../views/MarketView.vue')
  },

  {
    path: "/maintenance",
    name: "MaintenanceView",
    component: () =>
        import(/* webpackChunkName: "maintenance" */ "../views/MaintenanceView.vue"),
  },
]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return {
      top: 0,
    };
  }
})


function isLoggedIn() {
  return localStorage.getItem('token');
}

router.beforeEach((to, from, next) => {
  // console.log('route changessss=============', store.getters.getMaintenance);
  // console.log(to);

  store.getters.getMaintenance == "on" && to.name != "MaintenanceView"
      ? next({ name: "MaintenanceView" })
      : "";

  if (to.meta.authOnly && !isLoggedIn()) { next({ name: 'LoginView' }) }
  else if (to.meta.guestOnly && isLoggedIn()) { next({ name: 'Exchange' }) }
  else if (!to.name) { next({ name: 'Exchange' }) }
  else { next() }
})



export default router
